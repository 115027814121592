<template>
  <ValidationProvider :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules" v-slot="{ errors, valid }">
    <BField v-bind="$attrs" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
      <BInput v-model="innerValue" v-bind="$attrs"></BInput>
    </BField>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { BField } from 'buefy/dist/esm/field';
import { BInput } from 'buefy/dist/esm/input';

export default {
  components: {
    ValidationProvider,
    BField,
    BInput,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
