<template>
  <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules" v-slot="{ errors, valid }">
    <BField v-bind="$attrs" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
      <BSelect :placeholder="$attrs.placeholder" v-model="innerValue" :expanded="$attrs.expanded">
        <slot />
      </BSelect>
    </BField>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { BField } from 'buefy/dist/esm/field';
import { BSelect } from 'buefy/dist/esm/select';

export default {
  components: {
    ValidationProvider,
    BField,
    BSelect,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
